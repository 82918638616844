import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { Box, chakra, Center, Heading } from '@chakra-ui/react';
import { Banner } from 'exoclick-react';

import { BaseSeo, BaseScene } from '@components/base';
import '@styles/markdown.css';

export default function PolicyPageTemplate({ data }) {
  const { frontmatter, html } = data.markdownRemark;
  const { title } = frontmatter;

  return (
    <BaseScene as='article'>
      <chakra.header>
        <Heading as='h1' mb={4} textAlign='center'>
          {title}
        </Heading>
      </chakra.header>
      <Box className='markdown' dangerouslySetInnerHTML={{ __html: html }} />
      <Center pt={4} pb={4}>
        <Banner zoneId={4864560} />
      </Center>
    </BaseScene>
  );
}

export function Head({ data }) {
  const { pathname } = useLocation();
  const { site, markdownRemark } = data;
  const { siteUrl, siteTitle } = site.siteMetadata;
  const { title, datePublished, dateModified } =
    markdownRemark?.frontmatter || {};

  const schemaGraphs = React.useMemo(() => {
    const pageUrl = `${siteUrl}${pathname}`;

    return [
      {
        '@type': 'WebPage',
        '@id': pageUrl,
        url: pageUrl,
        name: `${title} ∘ ${siteTitle}`,
        isPartOf: {
          '@id': `${siteUrl}/#website`,
        },
        datePublished,
        dateModified,
        breadcrumb: {
          '@id': `${pageUrl}#breadcrumb`,
        },
        inLanguage: 'en-US',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: [pageUrl],
          },
        ],
      },
    ];
  }, [pathname, siteUrl, siteTitle, title, datePublished, dateModified]);

  return (
    <BaseSeo
      title={title}
      description={`View ${title}`}
      schemaGraphs={schemaGraphs}
    />
  );
}

export const pageQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteTitle: title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        datePublished
        dateModified
      }
    }
  }
`;
